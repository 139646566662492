<template>
  <nav class="header-categories container">
    <MCarousel
      arrow-size="2xs"
      with-gradient
    >
      <div
        v-for="(menuItem, i) in categories"
        :key="i"
        class="header-categories__item"
      >
        <ALink
          :class="[
            menuItem.icon,
            menuItem.accent && 'accent',
          ]"
          :href="menuItem.url"
          color="dark"
          size="sm"
        >
          {{ menuItem.title }}
        </ALink>
      </div>
      <div
        class="header-categories__item"
        @click="toggleMegamenu"
      >
        <ALink
          size="sm"
          color="dark"
        >
          {{ $t("categories.more") }}
        </ALink>
      </div>
    </MCarousel>
  </nav>
</template>

<script setup lang="ts">
import { defineComponent } from 'vue'
import { useHydrationData, useNavigate } from '#imports'
import { useCategories } from '@/stores/categories'
import { useSearchStore } from '@/stores/search'

import ALink from '@/components/atoms/Link/ALink.vue'
import MCarousel from '@/components/molecules/Carousel/MCarousel.vue'

defineComponent({ name: 'MHeaderBottom' })

const { toggleMegamenu } = useSearchStore()
const { getPopularSections } = useCategories()
const { getUrl } = useNavigate()

const { data: categories } = await useHydrationData('header-categories', async () => {
  const popularSections = await getPopularSections()

  const sectionsList = popularSections?.sectionsList || []

  return sectionsList.map((el) => {
    const item: { url: string, title: string, icon?: string, accent?: boolean } = {
      url: getUrl(el.path?.value || ''),
      title: el.title
    }

    if (el.icon) {
      item.icon = `i-${el.icon}`
    }

    if (el.path?.value?.includes?.('promo')) {
      item.accent = true
    }

    return item
  })
})
</script>

<style lang="postcss">
.header-categories {
  position: relative;
  display: none;

  &.container {
    padding-top: var(--spacer-3xs);
    padding-bottom: var(--spacer-2xs);
  }

  & .carousel {
    --carousel-gap: var(--spacer-3xl);
    --carousel-arrow-position: 0;

    height: 100%;
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacer-5xs) var(--spacer-4xs);
    text-transform: uppercase;

    &:first-child {
      padding-left: 0;
    }

    & .link.accent {
      color: var(--color-red-400);
    }

    &:last-child > .link {
      cursor: pointer;
    }
  }

  & .link {
    display: flex;
    align-items: center;

    &::before {
      width: 16px;
      height: 16px;
      margin-right: var(--spacer-4xs);
    }

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        &.i-wine-middle::before {
          background-image: url("/assets/icons/general.svg#wine-middle--hover");
        }

        &.i-whiskey-middle::before {
          background-image: url("/assets/icons/general.svg#whiskey-middle--hover");
        }

        &.i-cognac-midle::before {
          background-image: url("/assets/icons/general.svg#cognac-midle--hover");
        }

        &.i-champagne-middle::before {
          background-image: url("/assets/icons/general.svg#champagne-middle--hover");
        }

        &.i-beer-middle::before {
          background-image: url("/assets/icons/general.svg#beer-middle--hover");
        }
      }
    }
  }

  @media (--screen-lg) {
    display: flex;
  }
}
</style>
